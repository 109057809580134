/** @jsx jsx */
import React from 'react';
import { Text, Box, jsx } from 'theme-ui';
import Slider from 'react-slick';
import { ThemeHeader, ImageFullWidth } from '../../components';
import { CTALink } from '..';

const CardWithCTALinks = (props) => {
  let slider;

  const {
    pageBody: { heading, bgColor, cardCTALinksList },
  } = props;
  const [activeSlideIndex, setActiveSlideIndex] = React.useState(0);
  const [sliderSettings, setSliderSettings] = React.useState({
    fade: false,
    autoplay: false,
    infinite: false,
    autoplaySpeed: 6000,
    speed: 500,
    arrows: false,
    dots: false,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          dots: true,
        },
      },
    ],
    beforeChange: (currentIdx, NextIndx) => {
      setActiveSlideIndex(NextIndx);
    },
  });
  const renderSubLinks = (subLinks, idx) => (
    <Box>
      {subLinks.map((link) => (
        <Box key={Math.random().toString(36).substring(7)}>
          <CTALink key={Math.random().toString(36).substring(7)} to="/" linkData={link} />
        </Box>
      ))}
    </Box>
  );
  const getAutoSlideStyle = (isAuto) => {
    return isAuto ? autoSlideItem.item : {};
  };
  const renderLinksSection = (linksList, headingText) => {
    return (
      <Box>
        {headingText && <ThemeHeader.H2 styles={cardWithCTALinks.heading}>{headingText}</ThemeHeader.H2>}
        {linksList && (
          <Box>
            {linksList.map((link, index) => (
              <Box
                key={Math.random().toString(36).substring(7)}
                sx={{ ...linkItem.item, ...getAutoSlideStyle(index === activeSlideIndex) }}
                onMouseEnter={() => {
                  setActiveSlideIndex(index);
                  slider?.slickGoTo(index);
                }}
              >
                <Text sx={linkItem.mainText}>{link?.title}</Text>
                {link?._rawNavItems?.length > 0 && renderSubLinks(link._rawNavItems, index)}
              </Box>
            ))}
          </Box>
        )}
      </Box>
    );
  };

  return (
    <Box sx={cardWithCTALinks.base} style={{ backgroundColor: bgColor }}>
      <Box sx={cardWithCTALinks.wrap}>
        <Box sx={cardWithCTALinks.img}>
          <Slider
            ref={(c) => {
              if (c !== null) {
                slider = c;
              }
            }}
            {...sliderSettings}
          >
            {cardCTALinksList.map((imgObj) => {
              return (
                <ImageFullWidth
                  style={cardWithCTALinks.imgStyles}
                  key={imgObj._key}
                  src={imgObj?._rawLinkImage?.asset._ref}
                  alt={imgObj?._rawLinkImage?.asset._ref}
                />
              );
            })}
          </Slider>
        </Box>
        {cardCTALinksList && heading && (
          <Box id="checkID" sx={cardWithCTALinks.content}>
            {renderLinksSection(cardCTALinksList, heading)}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default CardWithCTALinks;

const cardWithCTALinks = {
  base: {
    pt: [null, null, 10],
  },
  wrap: {
    flexDirection: ['column', 'row', null],
    pt: [5, null, 9],
    display: ['block', 'flex', 'flex'],
  },
  img: {
    width: ['100%', null, '55.5%'],
  },
  content: {
    width: ['100%', null, '44.5%'],
    px: [2, null, 8],
    py: 5,
    textAlign: ['center', null, 'left'],
  },
  imgStyles: {
    minHeight: 700,
  },
  heading: {
    mb: [4, 5, 9],
    fontSize: 7,
    lineHeight: 1,
    whiteSpace: 'nowrap',
  },
};

const linkItem = {
  item: {
    position: 'relative',
    variant: 'styles.h3',
    fontStyle: 'italic',
    fontFamily: 'heading',
    transition: '0.3s ease',
    textTransform: 'capitalize',
    pt: 4,
    opacity: 0.35,
    cursor: 'pointer',
    lineHeight: 1,
    '> div': {
      maxHeight: 0,
      transition: '0.15s ease-in-out',
      overflow: 'hidden',
      opacity: 0,
    },
    '&:hover': {
      variant: 'styles.h2',
      textTransform: 'uppercase',
      opacity: 1,
      '> div': {
        maxHeight: 500,
        opacity: 1,
      },
    },
  },
  mainText: {
    display: 'block',
    pb: 3,
  },
};

const autoSlideItem = {
  item: {
    variant: 'styles.h2',
    textTransform: 'uppercase',
    opacity: 1,
    '> div': {
      maxHeight: 500,
      opacity: 1,
    },
  },
};
